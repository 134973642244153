import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mitt from 'mitt';
import sharedScripts from "@/dependencies/sharedScripts";

const eventBus = mitt();

import { saveAs } from 'file-saver';
const ExcelJS = require("exceljs");

// sessionStorage persistence
const storageKey = "reportApp";
const session = {
    get(key) {
        return JSON.parse(sessionStorage.getItem(key) || "{}");
    },
    add(key, value) {
        let storage = JSON.parse(sessionStorage.getItem(storageKey) || "{}");
        storage[key] = value;
        sessionStorage.setItem(storageKey, JSON.stringify(storage));
    },
    save(key, storage) {
        sessionStorage.setItem(key, JSON.stringify(storage));
    },
    deleteAll() {
        sessionStorage.removeItem(storageKey);
    }
}

let serverStatus = {
    serverBusy: false,
    busyText: ""
}

const authenticationCheck = async (vueInstance) => {
    // checkAndRefreshSession() returns either an updated state or a status object with errors
    let authenticationCheck = await sharedScripts.checkAndRefreshSession(vueInstance, vueInstance.reporterState);
    if (authenticationCheck.hasOwnProperty("ok") && !authenticationCheck.ok) {
        eventBus.emit("updateStatus", authenticationCheck);
        if (authenticationCheck.forceLogout) eventBus.emit("forceLogout");
        return false;
    } else {
        eventBus.emit("updateReporterState", authenticationCheck);
        return authenticationCheck;
    }
}

// globally set dateOptions
const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true
}

// globally set timeOptions
const timeOptions = {
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit"
};

// globally set dateOptions - Numbers Only
const shortDateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
};

// call to correct floating point imprecision
const roundFloat = (float) => {
    return Math.round((float + Number.EPSILON) * 100) / 100;
};

// Global app status object
const globalStatus = {
    code: null,
    message: null,
    ok: true,
    userMustDismiss: false,
    created: new Date().getTime(),
    createdTimeString: "",
    createdDateTimeString: "",
    clientSessonTimeout: false,
    forceLogout: false
};

// global default list limits
const limitOptions = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 },
    { text: "250", value: 250 },
    { text: "500", value: 500 }
];
let limit = 20;

// International currency conversion
const divisor = 100;
const toLocaleCurrencyIntl = (fundsAU) => {
    if (isNaN(fundsAU)) fundsAU = 0;
    return new Intl.NumberFormat(process.env.VUE_APP_LOCALE_STRING, { style: 'currency', currency: process.env.VUE_APP_CURRENCY }).format(fundsAU / divisor);
}

const calculatePaytableRTP = (item) => {
    return roundFloat(100 * item.paytablePrizeAU / (item.wagerRegularAU + item.wagerPromoAU));
}

const calculateActualRTP = (item) => {
    return roundFloat(100 * (item.paytablePrizeAU + item.progressivePrizeAU) / (item.wagerRegularAU + item.wagerPromoAU));
}

const millisecWeek = 604800000;
const millisecDay = 86400;
const timeZoneOffset = new Date().getTimezoneOffset() / 60

const addISOStringOffset = (datetime) => {
    let signLessOffsetString = Math.abs(timeZoneOffset).toString();
    let addZero = signLessOffsetString.length === 1;
    let offsetIsPositive = Math.sign(timeZoneOffset) === 1;
    let offsetString = `${offsetIsPositive ? "-" : "+"}${addZero ? "0" : ""}${signLessOffsetString}00`;
    return `${datetime}${offsetString}`;
}

const getLastArchiveWeekRange = (includeTime = false) => {
    let newDate = new Date(new Date().getTime() - millisecWeek);
    let endDate = new Date(new Date().getTime() - millisecWeek);
    let first = newDate.getDate() - newDate.getDay(); // First day is the day of the month minus the day of the week
    let last = first + 6; // last day is the first day + 6
    let start = new Date(newDate.setDate(first)).toISOString().slice(0, 10);
    let end = new Date(endDate.setDate(last)).toISOString().slice(0, 10);
    let dateRange = {
        start: includeTime ? `${start}T00:00` : start,
        end: includeTime ? `${end}T23:59` : end,
    };
    return dateRange;
}

const isoDateToExcelDate = (isoDateTime, dateTimeRangeDates = false, includeTime = false) => {
    let dateTime = new Date(isoDateTime);

    //added extra field to handle scenario where it adds the date time offset only for the start date and end date 
    if(dateTimeRangeDates) dateTime.setTime(dateTime.getTime() + (dateTime.getTimezoneOffset() * 60 * 1000));
    
    // 25569 is used as a conversion number for unix time to excel date time
    let excelDateTime = 25569.0 + ((dateTime.getTime() - (dateTime.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));

    // This is to resolve an off by one issue when "includeTime" is false
    // If I try and change it in the math above it breaks the "includeTime = true" date
    if (!includeTime) ++excelDateTime;
    return includeTime ? Number(excelDateTime.toString().substring(0, 20)) : Number(excelDateTime.toString().split('.')[0]);
}

const isoDayStart = (date = new Date()) => {
    // Takes an optional dateTime object and
    // either returns the ISO date string with the clock set to 00:00:00.000
    // or if no dateTime object passed in, returns ISO string for today at 00:00:00.000
    date.setHours(0, 0, 0, 0);
    return date.toISOString();
}

const setStylesForCells = (sheet, styles, offset) => {
    for (const property in styles) {
        sheet.getRow(offset).eachCell((cell) => {
            sheet.getCell(cell.address)[property] = styles[property];
        });
    }
}

const setSingleStyleForCells = (sheet, property, style, offset) => {
    sheet.getRow(offset).eachCell((cell) => {
        sheet.getCell(cell.address)[property] = style;
    });
}

const camelToTitleCase = (text) => {
    let result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

const app = createApp(App).use(store).use(router);

app.config.globalProperties.ExcelJS = ExcelJS;
app.config.globalProperties.eventBus = eventBus;
app.config.globalProperties.session = session;
app.config.globalProperties.serverStatus = serverStatus;
app.config.globalProperties.authenticationCheck = authenticationCheck;
app.config.globalProperties.dateOptions = dateOptions;
app.config.globalProperties.timeOptions = timeOptions;
app.config.globalProperties.shortDateOptions = shortDateOptions;
app.config.globalProperties.camelToTitleCase = camelToTitleCase;
app.config.globalProperties.roundFloat = roundFloat;
app.config.globalProperties.toLocaleCurrencyIntl = toLocaleCurrencyIntl;
app.config.globalProperties.calculatePaytableRTP = calculatePaytableRTP;
app.config.globalProperties.calculateActualRTP = calculateActualRTP;
app.config.globalProperties.globalStatus = globalStatus;
app.config.globalProperties.limitOptions = limitOptions;
app.config.globalProperties.limit = limit;
app.config.globalProperties.millisecWeek = millisecWeek;
app.config.globalProperties.millisecDay = millisecDay;
app.config.globalProperties.timeZoneOffset = timeZoneOffset;
app.config.globalProperties.addISOStringOffset = addISOStringOffset;
app.config.globalProperties.isoDayStart = isoDayStart;
app.config.globalProperties.getLastArchiveWeekRange = getLastArchiveWeekRange;
app.config.globalProperties.isoDateToExcelDate = isoDateToExcelDate;
app.config.globalProperties.setStylesForCells = setStylesForCells;
app.config.globalProperties.setSingleStyleForCells = setSingleStyleForCells;
app.config.globalProperties.rabbitsfootHostUrl = process.env.VUE_APP_RABBITSFOOT_HOST_URL;
app.config.globalProperties.devMode = process.env.VUE_APP_DEV_MODE === "true";
app.config.globalProperties.localeString = process.env.VUE_APP_LOCALE_STRING;
app.config.globalProperties.currency = process.env.VUE_APP_CURRENCY;
app.config.globalProperties.operatorAppsVersion = process.env.OPERATOR_APPS_VERSION;
app.config.globalProperties.phoneNumberLength = process.env.VUE_APP_PHONE_LENGTH;
app.config.globalProperties.serverName = process.env.VUE_APP_SERVER_NAME;
app.config.globalProperties.casinoGroupName = process.env.VUE_APP_CASINO_GROUP_NAME;

app.mount('#app');
